.pages {
    /* background-color: pink; */
    /* border: 1px solid black; */
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.pages h1 {
    text-align: center;
}
.pages h2 {
    text-align: left;
}

.pages p {
    font-size: 14px;
    text-align: left;
}

.pages ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

img {
    padding: 1rem;
    height: 30vw;
    width: auto;
    min-width: 250px;
    min-height: 250px;
    max-width: 300px;
    max-height: 300px;
    /* border: 4px solid black; */
}

img:hover {
    transform: scale(0.95);   
}

.intro {
    /* border: 4px solid black; */
}

.box1 {
    /* border: 4px solid black; */
    display: flex;
}
.box2 {
    /* border: 4px solid red; */
    display: flex;
}

.box3 {
    display:flex;
}

.box4 {
    display:flex;
}

.threeD {
    height: 40rem;
    width: 100%;
}


#portrait {
}

#ironman {
}

#grad {
}

#usnaGrad {
}

.paypal {
    /* max-width: 10rem; */
}

@media (max-width: 725px) {
    .box1 {
        flex-direction: column-reverse;
        align-items: center;
    }
    .box2 {
        flex-direction: column;
        align-items: center;
    }
    .box3 {
        flex-direction: column-reverse;
        align-items: center;
    }
    .box4 {
        flex-direction: column;
        align-items: center;
    }
    .pages {
        text-align: center;
    }
    .pages h2 {
        text-align: center;
    }
    .pages p {
        text-align: center;
    }
    .pages a {
        text-align: center;
    }
}