.navbar {
   font-weight: 700;
   color: black;
   /* background-color: rgb(0, 42, 255); */
   display: flex;
   align-items: center;
   justify-content: space-around;
   border-bottom: 1px solid black;
   padding: 1rem;
   /* border: 1px solid black; */
}

.navbar a{
   color:black;
}

.navbar a:hover {
   color: grey;
}

.span {
}

.nav-text {
   display: flex;
   flex-shrink: 0;
}

.nav-text a {
   text-decoration: none;
   color: black;
}

.site-title {
   font-size: 2rem;
   flex-shrink:0;
   flex-grow:.8;
   text-decoration: none;
   min-width: 4rem;
}

.burger {
   display: none;
}


@media (max-width: 700px) {
   .navbar {
   }
   .site-title {
      font-size: 3rem;
   }
   
  .nav-text {
   display: none;
   }
   
   .burger {
      display: block;
      scale: 2;
   }

   .burger:hover {   
      opacity: 50%;
   }

}
