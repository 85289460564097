.mobile-menu {
   position: fixed;
   top: 0;
   right: 0;
   min-height: 100vh;
   background-color: white;
   padding: 1rem;
   display: block;
   border-left: 1px solid black;
   z-index: 9999; /* Set a high z-index to ensure it appears on top */
  /* Other styles for your component */
}

.mobile-menu li {
   list-style-type: none;
}

.mobile-menu-text a{
   text-decoration: none;
}

.exit:hover {
   opacity: 50%;
}

@media (max-width:700px) {
   .mobile-menu {
      transition-duration: 500ms;
   }
   
}
