.footer {
  font-weight: 700;
  color: black;
 /* background-color: grey; */
  border-top: 1px solid black;
  justify-content: center;
  
 }
 h1,h2,h3,h4,h5,h6, ul {
  margin:0px;
 }

.container1 {
  display:flex;
  justify-content: space-around;
  /* background-color: red; */
  min-height: 15rem;
}

.contact {
  /* border: 2px solid black; */
  min-width: 10rem;
  padding: 1rem;
  max-width: 20rem;
  text-align: center;
}

.contact-icons {
  list-style-type: none;
  text-indent: none;
  padding-left: 0;
}

.contact a{
  text-decoration: none;
}

.subscribe {
  /* border: 2px solid black; */
  /* width: 30rem; */
  padding: 1rem;
  max-width: 30rem;
}

.subscribe button {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.subscribe button:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

.container2 {
  /* background-color: blue; */
  text-align: center;
}

.input {
  border: 1px solid black;
}

@media (max-width: 500px) {
  .container1 {
    flex-direction: column;
    align-items: center;
  }
  .contact {
    align-self: center;
    text-align: center;
  }
  .contact ul {
    text-decoration: none;
  }
  .subscribe {
    text-align: center;
  }    
}
