html {
   font-size: 12px;
}
.page-container {
   font-family: 'Courier New', sans-serif;
   /*provides buffer to edges of screen*/
   /* border: 5px solid green; */
   /* background-color: grey; */
   /* margin-left: auto; 
   margin-right: auto; */
   width: 85vw;
   height: 100%;
   min-height: 100%;
   margin-left: auto; 
   margin-right: auto; 
}

.content-wrapper {
   /* border: 5px solid red; */
   display: flex; /*allows stacking of navbar,page,footer components*/
   flex-direction: column; /*provides vertical direction for flex*/
   align-items: stretch; /*extnes boxes to edges of content-wrapper*/
   max-width: 1000px;
   margin-left: auto; 
   margin-right: auto; 
}

